import React from "react";
import "../css/Home.css";

export default function Home() {
  return (
    <div className="home">
      <div className="loader">
        <h2>ON</h2>
        <h2>DECK</h2>
        <h2>CONSULTING</h2>
      </div>
    </div>
  );
}
